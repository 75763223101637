<template>
  <!-- <div class="bcm-container">
    <b-row class="flex-column text-left collapse-container" no-gutters>
      <b-col
      class="sort-filter-section_collapse-item_row"
      v-for="subCategory in options"
      :key="subCategory.id"
      >
        <b-link :to="'listing' | localizedByNameCategories(subCategory.url_path)">
        {{subCategory.name}}
        </b-link>
      </b-col>
    </b-row>
  </div> -->
  <div class="block-filter">
    <div class="checkbox-filter">
      <no-ssr>
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            class="single-item"
            text-field="name"
            :options="options"
            name="radio-sort-category"
            value-field="url_path"
          ></b-form-radio-group>
        </b-form-group>
      </no-ssr>
    </div>
    <apply-filters @apply="applyFilter" :showClear="false" :disabled="disabled"></apply-filters>
  </div>
</template>

<script>
import Vue from 'vue'
import ApplyFilters from '../ApplyFilters'

export default {
  props: ['options'],
  data () {
    return {
      selected: ''
    }
  },
  computed: {
    disabled () {
      return this.selected === ''
    }
  },
  methods: {
    applyFilter () {
      this.$parent.toggleFilter()
      let url = Vue.filter('localizedByNameCategories')('listing', this.selected, null, this.$store, this.$router)
      this.$router.push({ path: url })
    }
  },
  components: {
    ApplyFilters
  }
}
</script>
