<template>
  <div class="block-filter">
    <b-dropdown
    :html="`<span>${$t('sort-by')}:  <span class='text-capitalize'>${getLabel(optionSelectedLabel)}</span></span>`"
    class="bcm-af-dropdown wrapp-listing-filters"
    :class="{ 'show-content': dropOpen }"
    @shown="dropShow()"
    @hide="dropHide()"
    ref="sortDropdown">
      <div class="simple-filter-dropdown__content">
        <div class="simple-filter-dropdown__content__body">
          <sort-filter-list
          @change="applyFilter($event)"
          ref="dropFilter"
          :options="options"
          :optionSelected="optionSelected"
          class="generic-filter"></sort-filter-list>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { EventBus } from '@/helpers'
import SortFilterList from './SortFilterList'
import _ from 'lodash'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      dropOpen: false
    }
  },
  computed: {
    optionSelectedLabel () {
      let currentSort = _.find(this.options, { value: this.optionSelected })
      return currentSort ? currentSort.label : ''
    }
  },
  methods: {
    dropShow () {
      this.dropOpen = true
      this.emitDropToggle(true)
    },
    dropHide () {
      this.dropOpen = false
      this.$refs.dropFilter.reInitFilter()
      this.emitDropToggle(false)
    },
    emitDropToggle (boolval) {
      EventBus.$emit('listing:dropOpen', boolval)
    },
    applyFilter ($event) {
      this.optionSelected = $event
      this.$refs.sortDropdown.hide(true)
      this.$refs.dropFilter.applyFilter()
    },
    resetSort () {
      this.$emit('sortChange', null)
      this.$refs.sortDropdown.hide(true)
    },
    getLabel (opt) {
      return this.$t(opt)
    }
  },
  components: {
    SortFilterList
  }
}
</script>
<style lang="scss">
.listing-page-container .sort-filter-section_grid .wrapp-sort .simple-sort-dropdown{
  .dropdown-menu{
    min-width: 20rem;
    right: 0!important;
    left: auto!important;
  }
}
</style>
