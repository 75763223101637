<template>
  <div class="block-filter">
    <!--<b-dropdown
    v-if="hasDrop"
    :text="optionSelected.name"
    class="simple-category-dropdown bcm-af-dropdown"
    no-flip
    @shown="dropShow()"
    @hide="dropHide()"
    ref="categoryDropdown">
      <div class="dropdown__content">
        <div class="dropdown__content__body">
          <category-filter-list
          ref="dropFilter"
          :options="options"
          :optionSelected="optionSelected"
          class="generic-filter" />
        </div>
      </div>
    </b-dropdown>
    <h1 class="title m-0" v-else>
      {{optionSelected.name}}
    </h1>-->
    <h6 v-if="parentCategoryName" class="subtitle">
      {{parentCategoryName}}
    </h6>
    <h2 class="title">
      {{optionSelected.name}}
    </h2>
    <div class="results">
      <span>{{$t('listing.results')}}</span>
      <div class="divider"></div>
      <span>{{totalResults}}</span>
    </div>
  </div>
</template>

<script>
import { remove } from 'lodash'
import { EventBus } from '@/helpers'
import CategoryFilterList from './CategoryFilterList'

export default {
  props: {
    resultsNum: {
      type: Number,
      required: false,
      default: 0
    },
    options: {
      type: Array,
      required: true
    },
    optionSelected: {
      type: Object,
      required: false
    }
  },
  computed: {
    parentCategoryName () {
      let parentCategoryName = ''
      if (this.$store.state.category) {
        const parentId = this.$store.state.category.current ? this.$store.state.category.current.parent_id : null
        const list = this.$store.state.category.list ? this.$store.state.category.list : null

        if (parentId && list) {
          for (let i in list) {
            const parentCategory = list[i].childrenData ? list[i].childrenData.find((childrenList) => childrenList.id === parentId) : null

            if (parentCategory) {
              parentCategoryName = parentCategory.name
              break
            }
          }
        }
      }
      return parentCategoryName
    },
    currentCategory () {
      return this.$store.state.category.current
    },
    optionSelectedLabel () {
      let currentCat = find(this.options, this.optionSelected)
      return currentCat ? currentCat.name : ''
    },
    hasDrop () {
      if (this.options.length) {
        const _this = this
        remove(this.options, { id: _this.currentCategory.id })
        return this.options.length
      }
      return false
    },
    totalResults () {
      return this.$store.getters['listing/getTotals']
    }
  },
  methods: {
    dropShow () {
      EventBus.$emit('listing:dropOpen', true)
    },
    dropHide () {
      EventBus.$emit('listing:dropOpen', false)
    }
  },
  components: {
    CategoryFilterList
  }
}
</script>
