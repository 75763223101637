<template>
  <div class="filter-block">
    <b-dropdown
    v-show="Object.keys(options).length"
    :html="`<span id='filterBy'>${$t('FilterBy')}${(totNumFiltersSelected > 0) ? `<span> (${totNumFiltersSelected})</span>` : ``}</span>`"
    :class="{ 'show-content': dropOpen }"
    class="bcm-af-dropdown wrapp-listing-filters"
    ref="filterDropdown"
    @shown="dropShow()"
    @hide="dropHide()">
      <div class="wrapp-listing-filters__content">
        <template v-for="(filter, attrCode) in options">
          <product-filter
            ref="dropFilter"
            :filter="filter"
            :key="filter.label"
            :attrCode="attrCode"
            @changeFilter="$emit('changeFilter', $event);updateTotNumFiltersSelected()"
            class="filter-column">
          </product-filter>
        </template>
      </div>
      <div class="filter-clear-wrapper" v-show="dropOpen && filtersActive">
        <button class="pagebuilder-button-link reset-filters" @click.prevent="clearFilters">{{$t('listing.clearFilters')}}</button>
        <button class="btn btn-primary apply-filters" @click.prevent="confirmAndCloseFilters">{{ $t('listing.confirmAndCloseFilters') }}</button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { EventBus } from '@/helpers'
import ProductFilter from './ProductFilter'
import $ from 'jquery'

export default {
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      totNumFiltersSelected: 0,
      dropOpen: false
    }
  },
  computed: {
    filtersActive () {
      let filters = this.$store.getters['listing/filterSelected']
      if (Object.keys(filters).length) {
        return true
      }
      return false
    }
  },
  methods: {
    updateTotNumFiltersSelected () {
      this.totNumFiltersSelected = (this.$store.state.listing.filters.chosen) ? [].concat.apply([], Object.values(this.$store.state.listing.filters.chosen)).length : 0
    },
    confirmAndCloseFilters () {
      $('#filterBy').click()
    },
    clearFilters () {
      if (this.$refs.dropFilter.length > 0) {
        this.$refs.dropFilter.forEach(filter => {
          filter.clearFilter()
        })
      }
      this.$store.commit('listing/clearFilters')
      return this.$emit('changeFilter')
    },
    dropShow () {
      this.dropOpen = true
      this.emitDropToggle(true)
    },
    dropHide () {
      this.dropOpen = false
      this.emitDropToggle(false)
    },
    emitDropToggle (boolval) {
      EventBus.$emit('listing:dropOpen', boolval)
    }
  },
  components: {
    ProductFilter
  }
}
</script>
