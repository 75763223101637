<template>
  <div class="filter-recap" v-show="filters.length && !this.$store.state.ui.isMobile">
    <div class="bcm-container">
      <div class="filters-list">
        <b-nav class="list nav align-items-center">
          <li><button class="btn-none clear-filter bcm-link p-0" @click.prevent="removeFilter()">{{$t('Clear all filter')}}</button></li>
          <li v-for="filter in filters" :key="filter.value">
            <b-btn class="btn-none active-filter" variant="link" @click.prevent="removeFilter(filter.value, filter.attrCode)">
              <span class="filter-label">{{filter.label}}</span>
              <span class="remove-filter">&times;</span>
            </b-btn>
          </li>
        </b-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      filtersSelected: 'listing/filterSelected',
      filterParentMap: 'listing/filterParentMap'
    }),
    filters () {
      let filters = []
      for (let i in this.filtersSelected) {
        this.filtersSelected[i].forEach(value => {
          if (Object.keys(this.filterParentMap).length > 0) {
            filters.push({
              'attrCode': i,
              label: this.filterParentMap[value].label,
              value: this.filterParentMap[value].value
            })
          }
        })
      }
      return filters
    }
  },
  methods: {
    removeFilter (newValues, attrCode) {
      // find filter selected in fileterSelected and remove
      let filters = []
      if (newValues && this.filtersSelected[attrCode] && this.filtersSelected[attrCode].length) {
        filters = this.filtersSelected[attrCode].filter(function (filter) {
          return filter !== newValues
        })
        this.$store.commit('listing/updateFilters', { attrCode: attrCode, valueSelected: filters })
      } else {
        this.$store.commit('listing/clearFilters')
      }
      this.$emit('changeFilter')
    }
  }
}
</script>

<style lang="scss">
@import "theme/scss/_variables.scss";
.filters-list {
  .active-filter {
    padding: 0;
    border: none;
    line-height: 1;
    margin-left: 15px;
    color: map-get($colors, black);

    &:hover {
      text-decoration: none;
      opacity: 0.8;
      color: inherit;
    }

    .remove-filter {
      margin-left: 4px;
      font-size: 18px;
    }
  }
}
</style>
