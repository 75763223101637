<template>
  <div class="checkbox-filter">
    <no-ssr>
      <b-form-group>
        <b-form-checkbox-group
        :name="`checkbox_${attrCode}`"
        v-model="currentChosen"
        :options="options"
        :class="`single-item checkbox_${attrCode}`"
        @change="change"
        @input="scrollTop()"
        />
      </b-form-group>
    </no-ssr>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Config from 'config'
import { scrollTo } from '@/helpers'
import { ManageAndSortAggregrateSizeFilter } from 'theme/store/modules/listing/listing-utils'

export default {
  props: {
    attrCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filtersSelected: [],
      currentChosen: []
    }
  },
  computed: {
    ...mapState({
      options (state) {
        const _this = this
        let filterOptions
        if (this.attrCode && state.listing.filters.available[this.attrCode]) {
          // let uniqFilterOptions = uniqBy(state.listing.filters.available[this.attrCode].options, 'label')
          let uniqFilterOptions = []
          // for every option except color filter
          if (this.attrCode !== 'color_filter') {
            state.listing.filters.available[this.attrCode].options.forEach(option => {
              // check if it already exist in uniqFilterOptions
              let isExist = uniqFilterOptions.findIndex(opt => opt.label === option.label)
              // if not
              if (isExist < 0) {
                // push it
                uniqFilterOptions.push({
                  label: option.label,
                  value: [ option.value ]
                })
              } else {
                // if exist, push the value to the value array
                uniqFilterOptions[isExist].value.push(option.value)
              }
            })
          } else {
            // for color filter
            state.listing.filters.available[this.attrCode].options.forEach(option => {
              uniqFilterOptions.push(option)
            })
          }

          filterOptions = uniqFilterOptions.map(opt => ({
            value: opt.value.constructor === Array ? opt.value.join(',') : opt.value,
            // html: opt.label + ` ( ${opt.count} )`
            html: _this.optionLabel(this.attrCode, opt)
          }))
          if (this.attrCode === 'color_filter') {
            filterOptions = filterOptions.filter(filterOption => !filterOption.html.includes('|'))
          }
          filterOptions = filterOptions.sort((a, b) => parseInt(a.html) - parseInt(b.html) ||
          a.html.length - b.html.length ||
          a.html.localeCompare(b.html))
          if (this.attrCode === 'size_filter') {
            filterOptions = ManageAndSortAggregrateSizeFilter(filterOptions)
          }
          return filterOptions
        }
        return []
      }
    })
  },
  mounted () {
    this.reInitFilter()
  },
  methods: {
    reInitFilter () {
      this.currentChosen = this.$store.state.listing.filters.chosen[this.attrCode]
        ? this.$store.state.listing.filters.chosen[this.attrCode].concat()
        : []
    },
    change (newValues) {
      let filters = this.getFilterCompose(newValues)
      this.$store.commit('listing/updateFilters', filters)
      this.$emit('changeFilter', filters)
    },
    clearFilter () {
      this.currentChosen.splice(0)
      this.applyFilter()
    },
    applyFilter () {
      let filters = this.getFilterCompose(this.currentChosen)
      this.$store.commit('listing/updateFilters', filters)
      this.$emit('changeFilter', filters)
    },
    getFilterCompose (newValues) {
      return { attrCode: this.attrCode, valueSelected: newValues }
    },
    optionLabel (attrCode, opt) {
      let defaultRes = ['0', '1'].includes(opt.label) ? opt.label === opt.value ? this.$t('label_' + opt.label) : opt.label : opt.label
      if (attrCode === 'color_filter') {
        if (opt.type) {
          return defaultRes + `<span class='swatch-color' style='background:${opt.type === 1 ? opt.swatch : opt.type === 2 ? `center / contain no-repeat url(${Config.API.swatchPath + opt.swatch})` : ''}'></span>`
        }
      }

      return defaultRes
    },
    scrollTop () {
      scrollTo({ top: 0 })
    }
  }
}
</script>
