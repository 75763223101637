<template>
  <div class="checkbox-filter">
    <fieldset class="form-group">
      <ul class="single-item pl-0">
        <li v-for="option in options"
          @click="toggleFilter(option.value)"
          :key="option.value"
          :class="{'text-underline': option.value === currentSelected}"
          class="custom-control custom-control-inline custom-radio">
          <label class="custom-control-label">{{optionsLabel(option.label)}}</label>
        </li>
      </ul>
    </fieldset>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Array | Object,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      currentSelected: this.optionSelected
    }
  },
  mounted () {
    this.reInitFilter()
  },
  methods: {
    reInitFilter () {
      this.currentSelected = this.optionSelected
    },
    toggleFilter (valueSelected) {
      this.currentSelected = valueSelected
      if (this.$route.query && this.$route.query.sort && this.$route.query.sort === this.currentSelected) {
        this.$router.push({ path: this.$route.path })
        return
      }
      this.$router.push({ query: Object.assign({}, this.$route.query, { sort: this.currentSelected }) })
    },
    optionsLabel (opt) {
      return this.$t(opt)
    }
  }
}
</script>
