<template>
    <div class="filters-action">
      <!-- <div class="filters-action__wrapp-button wrapp-button-filter button-clear-filter" v-if="showClear">
        <button class="btn btn-secondary clear-filter" :disabled="disabled" @click.prevent="$emit('clear')">{{ clearFilterLabel }}</button>
      </div> -->
      <div class="filters-action__wrapp-button button-apply-filter">
        <button class="btn btn-primary w-100 apply-filter" :disabled="disabled" @click.prevent="$emit('apply')">{{$t('view-results-filtered')}}</button>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    /* showClear: {
      type: Boolean,
      default: true
    }, */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }/*,
    clearFilterKey: {
      type: String,
      default: 'clear-filter'
    } */
  }
  /*,
  computed: {
    clearFilterLabel () {
      return this.$t(this.clearFilterKey)
    }
  } */
}
</script>
